import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { SEO } from '../components/seo';

function ShareAppPage({data}) {
    const { site:{ siteMetadata: { appUrl, title } } } = data;

    useEffect(() => {
        window.location.href = appUrl
    }, [])

    return (
        <div>
            <SEO
                title={title}
                description={`${title} now available on the iOS App Store to download`}
                keywords={[
                    'download simple workout tracker',
                    'download workout app',
                    'download workout tracker',
                    'download workout plan app',
                    'download gym log app',
                    'download fitness app on iOS'
                ]}
            />
            <p>Redirecting now...</p>
        </div>
    );
}

export default ShareAppPage;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                appUrl
            }
        }
    }
`;
